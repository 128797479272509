import { useEffect } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';

const TOS = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="mainCont relative">
     <Header />
      
      <div className="relative w-full overflow-hidden">
      <div className="mainCont z-30 w-full flex items-center justify-center flex-col text-center h-[100px]">
      
      <div className="relative z-30 customFont text-bg font-bold text-[35px] sm3:text-[38px] sm2:text-[45px] lg:text-[50px] leading-tight">TOS</div>
      </div>

      </div>


      <div className="min-h-[calc(100vh-400px)] my-5">
       
       <a href="/tos.pdf" download="TOS" className="flex items-center gap-3 bg-black text-white rounded-md w-fit text-center mx-auto px-5 py-4">Download TOS <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg></a>

      </div>

    <Footer />
    </div>
  )
}

export default TOS;