import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
     <div className="relative flex items-center justify-between whitespace-nowrap z-20 py-5">
      <Link to="/" className="flex w-fit"><img src="/images/logo.svg" alt="logo" className="w-[200px]" /></Link>
      <Link to="https://whop.com/orders/products/" target="_blank" className="flex w-fit bg-black text-white rounded-md px-8 py-4">Dashboard</Link>
     </div>
    </>
  )
}

export default Header