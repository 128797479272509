import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
     <div className="flex items-center justify-center flex-col gap-2 text-center font-medium border-t border-gray-300 py-3 mt-[100px]">
       <div className="flex items-center gap-3">
         <Link to="/privacy">Privacy</Link>
         <Link to="/imprint">Imprint</Link>
         <Link to="/tos">TOS</Link>
       </div>
       <div>Copyright 2023 FastLane. all rights reserved</div>
     </div>
    </>
  )
}

export default Footer