
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Imprint from "./pages/Imprint";
import TOS from "./pages/TOS";

function App() {
  return (
    <div >
      <Router>
         <Routes>
         <Route path="/" element={<Home/>} />
         <Route path="/privacy" element={<Privacy/>} />
         <Route path="/imprint" element={<Imprint/>} />
         <Route path="/tos" element={<TOS/>} />
         
         </Routes>
       </Router>
    </div>
  );
}

export default App;