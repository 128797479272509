import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";

export default function Home() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="mainCont relative">
      <Header/>
      
      <svg width="57" height="53" className="absolute top-10 -right-16" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z" fill="black"/></svg>
      <svg width="46" height="46" className="absolute top-26 -left-24" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.9117 4.97056L25.8375 21.6408L45.3253 32.8369L24.8128 23.6519L20.1867 45.6456L22.5835 23.2988L0.236642 25.6956L22.2304 21.0695L13.0454 0.556969L24.2415 20.0448L40.9117 4.97056Z" fill="black"/></svg>

     <div className="flex lg4:justify-between flex-col lg4:flex-row gap-20 lg4:gap-5">
      <div className="relative mt-10 sm:mt-20">
        <div className="relative z-20">
        <div className="text-[40px] sm2:text-[50px] sm:text-[60px] lg3:text-[70px] font-bold leading-[60px] sm2:leading-[70px]">Handle Queue-It.<br/> With Ease.</div>
        <div className="text-[17px] text-black text-opacity-80 font-medium capitalize mb-6 mt-4">Jump ahead with our software. No more waiting in long queues. Get straight to the action and seize the moment. Access instantly today.</div>
        <Link to="https://whop.com/checkout/plan_CVtFeT9bPqBkR?d2c=true" target="_blank" className="flex items-center gap-1 bg-black text-white rounded-md w-fit px-5 py-4">Start Your <div className="bg-gradient-to-r from-cyan-500 to-green-400 text-transparent bg-clip-text font-semibold bg-300% animate-gradient">For Free</div> Now! <p></p> <svg width="25" height="8" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z" fill="white"/></svg></Link>
        </div>
        <img src="/images/banner.svg" alt="banner" draggable={false} className="lg2:min-w-[650px] -mb-32 mx-auto lg4:mx-0 lg2:-ml-[50px]" />
        <img src="/images/circle1.svg" alt="circle" className="absolute -top-20 sm2:-top-32 right-[20%] lg4:right-0" />
      </div>
      <img src="/images/hero.svg" alt="hero" className="hidden lg4:flex w-[500px] lg4:w-auto lg4:min-w-[600px] lg2:translate-x-20 mx-auto lg4:-mx-20 -mt-[50px] lg2:-mt-[150px]" />
     </div>
     
     <div className="relative items-center grid grid-cols-1 lg4:grid-cols-2 gap-20 mt-[200px] lg4:mt-[150px]">
      <Fade left>
      <img src="/images/settings.svg" alt="settings" className="w-full sm:w-auto sm:min-w-[600px] lg2:min-w-[800px] -mt-[30px] lg4:mt-[50px] lg2:mt-0 mx-auto lg4:-ml-[70px] lg2:-ml-[150px] order-2 lg4:order-none lg4:col-start-1" />
      </Fade>
      <Fade right>
      <div className="relative z-20 order-1 lg4:order-none lg4:col-start-2">
        <div className="text-[#FF5555] font-medium uppercase mb-2">features</div>
        <div className="text-[35px] sm2:text-[40px] sm:text-[50px] font-bold leading-[50px] mb-5 sm:mb-7">FastLane Access</div>
         <div className="flex flex-col gap-7">
          <div>
           <div className="flex items-center gap-3 mb-3">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1L9.6985 6.20599C9.44454 7.22185 9.31756 7.72978 9.05308 8.14309C8.81915 8.50868 8.50868 8.81915 8.14309 9.05308C7.72978 9.31756 7.22185 9.44454 6.20599 9.6985L1 11L6.20599 12.3015C7.22185 12.5555 7.72978 12.6824 8.14309 12.9469C8.50868 13.1808 8.81915 13.4913 9.05309 13.8569C9.31756 14.2702 9.44454 14.7782 9.6985 15.794L11 21L12.3015 15.794C12.5555 14.7782 12.6824 14.2702 12.9469 13.8569C13.1808 13.4913 13.4913 13.1808 13.8569 12.9469C14.2702 12.6824 14.7782 12.5555 15.794 12.3015L21 11L15.794 9.6985C14.7782 9.44454 14.2702 9.31756 13.8569 9.05308C13.4913 8.81915 13.1808 8.50868 12.9469 8.14309C12.6824 7.72978 12.5555 7.22185 12.3015 6.20599L11 1Z" stroke="#FF5555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            <div className="text-[20px] font-[600]">Cutting Edge Technology</div>
           </div>
           <div className="text-[15px] text-black text-opacity-80 font-medium capitalize">We harness cutting-edge technology to ensure you're always a step ahead. Experience the pinnacle of innovation and efficiency.</div>
          </div>

          <div>
           <div className="flex items-center gap-3 mb-3">
           <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.50002V12M12 12L20.5 7.27773M12 12L3.5 7.27773M12 12V21.5M20.5 16.7222L12.777 12.4317C12.4934 12.2741 12.3516 12.1954 12.2015 12.1645C12.0685 12.1371 11.9315 12.1371 11.7986 12.1645C11.6484 12.1954 11.5066 12.2741 11.223 12.4317L3.5 16.7222M21 16.0586V7.94147C21 7.59883 21 7.4275 20.9495 7.27471C20.9049 7.13953 20.8318 7.01545 20.7354 6.91076C20.6263 6.79242 20.4766 6.70922 20.177 6.54282L12.777 2.43171C12.4934 2.27415 12.3516 2.19537 12.2015 2.16448C12.0685 2.13715 11.9315 2.13715 11.7986 2.16448C11.6484 2.19537 11.5066 2.27415 11.223 2.43171L3.82297 6.54282C3.52345 6.70922 3.37369 6.79242 3.26463 6.91076C3.16816 7.01545 3.09515 7.13953 3.05048 7.27471C3 7.42751 3 7.59883 3 7.94147V16.0586C3 16.4012 3 16.5725 3.05048 16.7253C3.09515 16.8605 3.16816 16.9846 3.26463 17.0893C3.37369 17.2076 3.52345 17.2908 3.82297 17.4572L11.223 21.5683C11.5066 21.7259 11.6484 21.8047 11.7986 21.8356C11.9315 21.8629 12.0685 21.8629 12.2015 21.8356C12.3516 21.8047 12.4934 21.7259 12.777 21.5683L20.177 17.4572C20.4766 17.2908 20.6263 17.2076 20.7354 17.0893C20.8318 16.9846 20.9049 16.8605 20.9495 16.7253C21 16.5725 21 16.4012 21 16.0586Z" stroke="#FF5555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            <div className="text-[20px] font-[600]">Unparalleled Performance</div>
           </div>
           <div className="text-[15px] text-black text-opacity-80 font-medium capitalize">Experience unmatched speed and reliability with our tool. Dive into an optimized journey powered by state-of-the-art engineering.</div>
          </div>

          <div>
           <div className="flex items-center gap-3 mb-3">
           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 14H6M6 14V1.5M6 14L1.5 18.5M1.5 6H14M14 6V18.5M14 6L18.5 1.5M19 13.3373V1.8C19 1.51997 19 1.37996 18.9455 1.273C18.8976 1.17892 18.8211 1.10243 18.727 1.0545C18.62 1 18.48 1 18.2 1H6.66274C6.41815 1 6.29586 1 6.18077 1.02763C6.07873 1.05213 5.98119 1.09253 5.89172 1.14736C5.7908 1.2092 5.70432 1.29568 5.53137 1.46863L1.46863 5.53137C1.29568 5.70432 1.2092 5.7908 1.14736 5.89172C1.09253 5.98119 1.05213 6.07873 1.02763 6.18077C1 6.29586 1 6.41815 1 6.66274V18.2C1 18.48 1 18.62 1.0545 18.727C1.10243 18.8211 1.17892 18.8976 1.273 18.9455C1.37996 19 1.51997 19 1.8 19H13.3373C13.5818 19 13.7041 19 13.8192 18.9724C13.9213 18.9479 14.0188 18.9075 14.1083 18.8526C14.2092 18.7908 14.2957 18.7043 14.4686 18.5314L18.5314 14.4686C18.7043 14.2957 18.7908 14.2092 18.8526 14.1083C18.9075 14.0188 18.9479 13.9213 18.9724 13.8192C19 13.7041 19 13.5818 19 13.3373Z" stroke="#FF5555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            <div className="text-[20px] font-[600]">Custom Captcha Solving</div>
           </div>
           <div className="text-[15px] text-black text-opacity-80 font-medium capitalize">We offer automatic captcha solving using both our proprietary solvers and trusted third-party solutions, ensuring a smooth and efficient experience.</div>
          </div>
         </div>
      </div>
      </Fade>
      <img src="/images/circle2.svg" alt="circle bg" className="absolute top-20 -right-[300px] w-[500px]" />
      <svg width="57" height="53" className="absolute -bottom-[80px] -right-20" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z" fill="black"/></svg>
     </div>


     
     <div className="relative grid grid-cols-1 lg4:grid-cols-2 items-center gap-20 mt-[50px] lg4:mt-[50px]">
      <Fade left>
      <div className="relative z-20">
        <div className="text-[#FF5555] font-medium uppercase mb-2">Advatnages</div>
        <div className="text-[30px] sm:text-[40px] font-bold leading-[40px] sm:leading-[50px] mb-7">Why Choose FastLane?</div>
          <div>
           <div className="flex items-center gap-3 mb-3">
            <svg width="45" height="45" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24.0001" cy="24.488" r="24" fill="#00C9FF"/><path d="M26.0001 33.488H22.0001M30.0001 20.488C30.0001 18.8967 29.3679 17.3706 28.2427 16.2454C27.1175 15.1202 25.5914 14.488 24.0001 14.488C22.4088 14.488 20.8827 15.1202 19.7574 16.2454C18.6322 17.3706 18.0001 18.8967 18.0001 20.488C18.0001 23.5782 17.2205 25.694 16.3497 27.0934C15.6152 28.2739 15.2479 28.8641 15.2614 29.0288C15.2763 29.2111 15.3149 29.2806 15.4619 29.3896C15.5945 29.488 16.1927 29.488 17.3889 29.488H30.6112C31.8075 29.488 32.4056 29.488 32.5383 29.3896C32.6852 29.2806 32.7238 29.2111 32.7388 29.0288C32.7522 28.8641 32.385 28.2739 31.6504 27.0935C30.7796 25.694 30.0001 23.5782 30.0001 20.488Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            <div className="text-[25px] font-[600]">Fast Updates</div>
           </div>
           <div className="text-[16px] text-black text-opacity-80 font-medium capitalize">In the ever-evolving digital landscape, staying updated is crucial. We pride ourselves on delivering fast updates, ensuring our software not only keeps pace with the latest advancements but often leads the charge. Benefit from a tool that's consistently refined and enhanced for your ever-changing needs.</div>
          </div>
      </div>
      </Fade>

      <Fade right>
      <img src="/images/api.svg" alt="api" className="w-full sm:w-auto sm:min-w-[500px] lg:min-w-[600px] lg2:min-w-[700px] mx-auto lg4:mx-0 lg4:-ml-[50px] lg:-ml-[100px] -mt-[80px] lg:-mt-[30px]" />
      </Fade>
      <svg width="46" height="46" className="absolute -bottom-[20px] left-1/2 -translate-x-1/2" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.9117 4.97056L25.8375 21.6408L45.3253 32.8369L24.8128 23.6519L20.1867 45.6456L22.5835 23.2988L0.236642 25.6956L22.2304 21.0695L13.0454 0.556969L24.2415 20.0448L40.9117 4.97056Z" fill="black"/></svg>
     </div>


     <div className="relative grid grid-cols-1 lg4:grid-cols-2 gap-28 lg4:gap-20 mt-[30px] lg4:mt-[150px]">
      <Fade left>
       <img src="/images/wifi.svg" alt="wifi" className="w-full sm:w-auto sm:min-w-[500px] lg2:min-w-[600px] mx-auto lg2:mx-0 lg2:-ml-[50px] -mt-[120px] order-2 lg4:order-none lg4:col-start-1" />
      </Fade>
      <Fade right>
      <div className="relative z-20 order-1 lg4:order-none lg4:col-start-2">
          <div>
           <div className="flex items-center gap-3 mb-3 mt-[50px]">
           <svg width="45" height="45" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24.9761" r="24" fill="#00C9FF"/><path d="M24 14.9761L22.6985 20.1821C22.4445 21.1979 22.3176 21.7059 22.0531 22.1192C21.8192 22.4848 21.5087 22.7952 21.1431 23.0292C20.7298 23.2936 20.2218 23.4206 19.206 23.6746L14 24.9761L19.206 26.2776C20.2218 26.5315 20.7298 26.6585 21.1431 26.923C21.5087 27.1569 21.8192 27.4674 22.0531 27.833C22.3176 28.2463 22.4445 28.7542 22.6985 29.7701L24 34.9761L25.3015 29.7701C25.5555 28.7542 25.6824 28.2463 25.9469 27.833C26.1808 27.4674 26.4913 27.1569 26.8569 26.923C27.2702 26.6585 27.7782 26.5315 28.794 26.2776L34 24.9761L28.794 23.6746C27.7782 23.4206 27.2702 23.2936 26.8569 23.0292C26.4913 22.7952 26.1808 22.4848 25.9469 22.1192C25.6824 21.7059 25.5555 21.1979 25.3015 20.1821L24 14.9761Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            <div className="text-[30px] font-[600]">Lightning fast</div>
           </div>
           <div className="text-[17.5px] text-black text-opacity-80 font-medium capitalize">Experience unparalleled speed like never before. Our software is optimized to give you the quickest response times, ensuring that your tasks are completed in a flash. Don't just keep up; accelerate ahead.</div>
          </div>
      </div>
      </Fade>

      <svg width="57" height="53" className="absolute -bottom-[50px] right-[80px]" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z" fill="black"/></svg>
     </div>



     <div className="relative bg-black text-white rounded-lg w-full overflow-hidden px-8 pb-[100px] pt-[50px] lg4:pt-[100px]">
      <div className="relative flex lg4:items-center lg4:justify-between gap-16 lg4:gap-5 flex-col lg4:flex-row z-20">
      <Fade left>
       <div>
       <div className="text-[35px] sm2:text-[40px] font-bold leading-[50px] mb-3">Ready To Get Started?</div>
        <div className="text-[16px] text-white capitalize">Dive into a world of efficiency and innovation. With our<br className="hidden lg:flex"/> cutting-edge solutions at your fingertips, the future is now.</div>
        <button className="bg-white text-black font-medium rounded-md mt-5 px-6 py-3"><Link to="https://whop.com/checkout/plan_CVtFeT9bPqBkR?d2c=true" target="_blank">JOIN THE TEAM!</Link></button>
       </div>
       </Fade>
       <Fade right>
        <img src="/images/circle-black.svg" alt="circle black" className="-mb-[150px] mx-auto lg4:-mx-20 -mt-[50px]" />
       </Fade>
      </div>
      
      <img src="/images/lines1.svg" alt="lines1" className="absolute bottom-0 left-0" />
      <img src="/images/lines2.svg" alt="lines2" className="absolute top-0 right-0" />
      <svg width="57" height="53" className="absolute top-[50px] right-[400px]" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z" fill="white"/></svg>
      <svg width="46" height="46" className="absolute bottom-[50px] left-[400px]" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.9117 4.97056L25.8375 21.6408L45.3253 32.8369L24.8128 23.6519L20.1867 45.6456L22.5835 23.2988L0.236642 25.6956L22.2304 21.0695L13.0454 0.556969L24.2415 20.0448L40.9117 4.97056Z" fill="white"/></svg>
     </div>

     <Footer/>

    </div>
  );
}